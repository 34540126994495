.App {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #ffffff;
  overflow: hidden;
}

.AppDark {
  margin: 0 auto;
  padding: 0;
  scroll-behavior: smooth;
  background: #14172d;
  overflow: hidden;
}

* {
  scroll-behavior: smooth;
}

.homepage-container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}

.gradient-text {
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-contact-input {
  font-family: "Poppins", sans-serif;
  width: 100%;
  border: none !important;
  font-size: 16px;
  /* color: #000; */
  background: transparent !important;
}

.custom-contact-input:hover {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.custom-contact-input:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none;
}

.custom-contact-input:active {
  border: none;
  box-shadow: none;
  outline: none;
}

.custom-contact-input::placeholder {
  /* color: #222222; */
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

/* navbar */

.navbar-btn {
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
}

header {
  width: 100%;
  height: 100px;
  background: transparent;
  transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  z-index: 999;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
}

.shrink {
  height: 80px;
  /* background: #fff; */
  z-index: 999;
}

.header-dropdown {
  backdrop-filter: blur(40px) !important;
  background: #c4c4c44d !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown .ant-dropdown-menu,
:where(.css-dev-only-do-not-override-amq5gd).ant-dropdown-menu-submenu
  .ant-dropdown-menu {
  border-radius: 5px;
  font-family: Helvetica, sans-serif;
}

nav ul {
  list-style: none;
  text-align: center;
}
nav ul li {
  display: inline-block;
}
nav ul li a {
  display: block;
  padding: 7px 15px;
  text-decoration: none;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
  transition: all 0.5s;
}

nav.shift ul li a {
  position: relative;
  z-index: 1;
}

nav.shift ul li a:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: ".";
  color: transparent;
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
  visibility: none;
  opacity: 0;
  z-index: -1;
  border-radius: 10px;
}
nav.shift ul li a:hover:after {
  opacity: 1;
  visibility: visible;
  height: 100%;
}

.menu-text-light {
  color: #14172d !important;
}

.menu-text-dark {
  color: #f6f6f6 !important;
}

.calendly-overlay .calendly-close-overlay {
  backdrop-filter: blur(4px);
}

/* hero */

.hero-bg {
  background: url("./assets/images/hero-bg.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  /* height: 80vh; */
}

.hero-btn {
  box-shadow: 0px 4px 4px 0px #00000040;
}

.hero-title-animation {
  animation: slideFromLeft 5s ease-in-out 1;
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-1000px);
  }
  50% {
    transform: translateX(200px);
  }
  75% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0);
  }
}

.hero-subtitle-animation {
  animation: slideFromRight 5s ease-in-out 1;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(1000px);
  }
  50% {
    transform: translateX(-200px);
  }
  75% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}

.custom-btn {
  position: relative;
  transition: all 1.5s;
  overflow: hidden;
  transition: all 0.3s linear;
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
  color: #fff;
}

.custom-btn .custom-btn-inner {
  position: relative;
  z-index: 999;
}

.custom-btn:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, #0010001f 1.42%, #0010001f 97.8%);
  transition: all 0.6s;
}

.custom-btn:hover {
  color: #fff;
}

.custom-btn:hover:after {
  width: 100%;
  background: linear-gradient(90deg, #0010001f 1.42%, #0010001f 97.8%);
  color: white;
}

/* booking */

.booking-bg {
  background: linear-gradient(
    72.47deg,
    rgba(35, 0, 191, 0.06) -44.32%,
    rgba(218, 148, 255, 0.06) 144.14%
  );
}

.custom-btn2 {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: 0.7s ease;
  z-index: 1;
  background: linear-gradient(90deg, #b514fd 1.42%, #5f75f5 97.8%);
}

.custom-btn2:before,
.custom-btn2:after {
  color: #fff;
  background: linear-gradient(90deg, #0010001f 1.42%, #0010001f 97.8%);
  transition: 0.7s ease;
  content: "";
  position: absolute;
  z-index: -1;
}

.middle-out:before {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.middle-out:hover:before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.testimonial1-bg {
  border: 1px solid #f2f3f8;
}

.testimonial2-bg {
  box-shadow: 0px 20px 60px -20px #16161629;
}

.testimonail-card-vh {
  min-height: 250px;
}

/* contact */

.contact-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #b514fd;
  width: 20px;
  height: 20px;
}

.contact-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox
  .ant-checkbox-inner {
  border: 1px solid #d9d9d9;
  width: 20px;
  height: 20px;
}

.contact-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-amq5gd).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #b514fd;
  width: 20px;
  height: 20px;
}

.contact-checkbox
  :where(.css-dev-only-do-not-override-amq5gd).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #b514fd;
  width: 20px;
  height: 20px;
}

/* media queries */

@media screen and (max-width: 1279px) and (min-width: 1024px) {
  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }
  .testimonail-card-vh {
    min-height: 220px;
  }
  nav ul li a {
    display: block;
    padding: 7px 10px;
    text-decoration: none;
  }
}

@media screen and (max-width: 1023px) and (min-width: 900px) {
  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }
  .testimonail-card-vh {
    min-height: 220px;
  }
}

@media screen and (max-width: 899px) and (min-width: 768px) {
  .testimonail-card-vh {
    min-height: 220px;
  }

  .custom-contact-input {
    font-size: 15px;
  }

  .custom-contact-input::placeholder {
    font-size: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 426px) {
  .testimonail-card-vh {
    min-height: 210px;
  }

  .custom-contact-input {
    font-size: 14px;
  }

  .custom-contact-input::placeholder {
    font-size: 14px;
  }
}

@media screen and (max-width: 425px) {
  .custom-contact-input {
    font-size: 14px;
  }

  .custom-contact-input::placeholder {
    font-size: 14px;
  }

  header {
    width: 100%;
    height: 72px;
    background: transparent;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    z-index: 999;
    top: 0;
    position: fixed;
  }
}
